import React from 'react';

const WelcomeScreen = () => (
    <div className="welcome-screen">
        <h1>Welcome!</h1>
        <h2>Please select a tab to get started.</h2>
        <div>
            Here is what you can do in this application:
            <ul>
                <li><strong>Tickets:</strong> View and manage tickets.</li>
                <li><strong>Chat Histories:</strong> View chat histories.</li>
                <li><strong>Drivers:</strong> Manage drivers.</li>
                <li><strong>Daily Report:</strong> View daily reports.</li>
                <li><strong>Monthly Report:</strong> View monthly reports.</li>
            </ul>
        </div>
    </div>
);

export default WelcomeScreen;
