import React from 'react';
import { FaEdit, FaTrash, FaPlay, FaStop } from 'react-icons/fa';

const DriverItem = ({ driver, selectedDate, onDriverClick, onDeleteDriver, onToggleDriverStatus }) => {
    const today = new Date().toISOString().substr(0, 10);
    const isPastDate = selectedDate < today;

    return (
        <li onClick={() => onDriverClick(driver)}>
            {driver.name}
            <div className="buttons">
                {!isPastDate && !driver.isClosed && (
                    <button onClick={(event) => {
                        event.stopPropagation();
                        onToggleDriverStatus(driver.id, driver.isActive, selectedDate);
                    }}>
                        {driver.isActive ? <FaStop color="red" /> : <FaPlay color="green" />}
                    </button>
                )}
                <button onClick={(event) => {
                    event.stopPropagation();
                    onDeleteDriver(driver.id);
                }}><FaTrash /></button>
            </div>
        </li>
    );
};

export default DriverItem;
