import React, { useState, useEffect } from 'react';
import DriverTicketItem from './DriverTicketItem';
import { FaChevronDown } from 'react-icons/fa';
import { collection, getDocs } from 'firebase/firestore';
import { useAuth } from '../../../../context/AuthContext';
import { sohoPizzaDb } from '../RestaurantUser';

const getNYDate = () => {
    const now = new Date();
    const offset = now.getTimezoneOffset() * 60000;
    const newYorkTime = new Date(now.getTime() + offset + (-5 * 3600000));
    if (newYorkTime.getHours() < 6) {
        newYorkTime.setDate(newYorkTime.getDate() - 1);
    }
    return newYorkTime.toISOString().split('T')[0];
};

const DriverTicketList = ({ notPaidDeliveryTickets = [], selectedTickets = [], handleTicketSelection, handleSelectAllTickets, assignTicketsToDriver, setSelectedDate: setSelectedDateProp }) => {
    const { user } = useAuth();
    const [selectedDate, setSelectedDate] = useState(getNYDate());
    const [showDriversList, setShowDriversList] = useState(false);
    const [activeDrivers, setActiveDrivers] = useState([]);

    const getFormattedCompanyForPath = () => {
        return user?.company.replace(/\s+/g, '');
    };

    useEffect(() => {
        setSelectedDateProp(selectedDate);
    }, [selectedDate, setSelectedDateProp]);

    const fetchActiveDrivers = async () => {
        try {
            const collectionRef = collection(sohoPizzaDb, `companies/${getFormattedCompanyForPath()}/drivers`);
            const querySnapshot = await getDocs(collectionRef);
            const driversData = querySnapshot.docs
                .map(doc => ({ id: doc.id, ...doc.data() }))
                .filter(driver => driver.isActive);
            setActiveDrivers(driversData);
        } catch (error) {
            console.error('Error fetching drivers:', error);
        }
    };

    const handleAssignButtonClick = async () => {
        await fetchActiveDrivers();
        setShowDriversList(!showDriversList);
    };

    const handleDriverClick = async (driverId) => {
        await assignTicketsToDriver(driverId);
        setShowDriversList(false);  // Driver seçildikten sonra listeyi kapat
    };

    const today = new Date().toISOString().substr(0, 10);
    const isCurrentDate = selectedDate === today;

    return (
        <div className="tickets-container">
            <div className="tickets-header">
                <div className="header-actions">
                    {isCurrentDate && (
                        <>
                            <label className="select-all-button">
                                <input
                                    type="checkbox"
                                    onChange={handleSelectAllTickets}
                                    checked={selectedTickets.length === notPaidDeliveryTickets.length}
                                    className="select-all-checkbox"
                                />
                                <span className="select-all-text">Select All</span>
                            </label>
                            <div className="assign-tickets-button-container">
                                <button className="assign-tickets-button" onClick={handleAssignButtonClick}>
                                    <FaChevronDown />
                                </button>
                                {showDriversList && (
                                    <div className="active-drivers-list">
                                        {activeDrivers.map(driver => (
                                            <div key={driver.id} onClick={() => handleDriverClick(driver.id)}>
                                                {driver.name}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
                <input
                    type="date"
                    value={selectedDate}
                    onChange={e => setSelectedDate(e.target.value)}
                    className="custom-drivers-date-picker"
                />
            </div>
            <div className="tickets-content">
                {notPaidDeliveryTickets.length === 0 ? (
                    <div>No tickets found.</div>
                ) : (
                    notPaidDeliveryTickets.map(ticket => (
                        <DriverTicketItem
                            key={ticket.id}
                            ticket={ticket}
                            selectedTickets={selectedTickets}
                            handleTicketSelection={handleTicketSelection}
                            isCurrentDate={isCurrentDate}
                        />
                    ))
                )}
            </div>
        </div>
    );
};

export default DriverTicketList;
