import React, { useState, useEffect } from 'react';
import '../../../../css/user.css';
import '../../../../css/sohopizza/tickets.css';
import axios from 'axios';
import { useAuth } from '../../../../context/AuthContext';

const Tickets = ({ tickets, date, setDate, selectedTicket, handleSelectItem, setActiveTab }) => {
    const [ticketDetails, setTicketDetails] = useState(null);
    const { user } = useAuth();

    useEffect(() => {
        if (selectedTicket) {
            const ticket = tickets.find(ticket => ticket.id === selectedTicket);
            if (ticket) {
                setTicketDetails(ticket);
            } else {
                setTicketDetails(null);
            }
        }
    }, [selectedTicket, tickets]);

    const handlePrint = async () => {
        if (!ticketDetails) {
            alert('Selected ticket not found.');
            return;
        }

        const data = {
            order_info: ticketDetails.data,
            name: ticketDetails.data.customer_name,
            number: ticketDetails.data.phone_number,
            company: user?.company.split(' ')[0].toUpperCase(),
        };

        try {
            await axios.post('https://wired-sol-435210-d9.uk.r.appspot.com/printticket', data);
            alert('Print request sent successfully');
        } catch (error) {
            console.error('Error printing ticket:', error);
            alert('Failed to send print request');
        }
    };

    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return phoneNumber;
    };

    const renderTicketDetails = () => {
        if (!ticketDetails) {
            return <div>Selected ticket not found.</div>;
        }

        const data = ticketDetails.data;
        const formattedDateTime = new Date(data.date_time).toLocaleString('en-US', { weekday: 'long', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' });

        const handleGoToChatHistory = () => {
            const chatHistoryId = `${data.customer_name}_${data.phone_number}`;
            handleSelectItem(chatHistoryId);
            setActiveTab('ChatHistories');
        };

        const renderDetail = (label, value) => {
            if (value === undefined || value === null || value.trim() === '' || value === 'N/A') return null;
            return <p><strong>{label}:</strong> {value}</p>;
        };

        return (
            <div className="custom-ticket-details">
                <div className="ticket-details-left">
                    <div className="ticket-header">
                        <strong className="order-type">
                            {`${data.order_type.charAt(0).toUpperCase() + data.order_type.slice(1)} ${data.ticket_number}`}
                            <span className={`payment-status ${data.payment_status === 'PAID' ? 'paid' : 'not-paid'}`} style={{ marginLeft: '10px' }}>
                                {data.payment_status}
                            </span>
                        </strong>
                        <div className="date-time">{formattedDateTime}</div>
                    </div>
                    <div className="ticket-body">
                        <p>{data.customer_name}, {formatPhoneNumber(data.phone_number)}</p>
                        {data.address !== 'N/A' && <p className="address">{data.address}</p>}
                        {data.order_total_before_tax !== undefined && renderDetail('Total Before Tax', `$${data.order_total_before_tax.toFixed(2)}`)}
                        {data.tax !== undefined && renderDetail('Tax', `$${data.tax.toFixed(2)}`)}
                        {data.tip !== undefined && renderDetail('Tip', `$${data.tip.toFixed(2)}`)}
                        {data.order_total_after_tax !== undefined && renderDetail('FINAL TOTAL', `$${data.order_total_after_tax.toFixed(2)}`)}
                        {renderDetail('Instructions', data.instructions)}
                    </div>
                </div>
                <div className="ticket-details-right">
                    <h4>Order Details:</h4>
                    {data.order_details && data.order_details.length > 0 && (
                        <ul>
                            {data.order_details.map((detail, index) => (
                                <li key={index}>{detail}</li>
                            ))}
                        </ul>
                    )}
                    <div className="custom-buttons">
                        <button className="custom-print-button" onClick={handlePrint}>Print</button>
                        <button className="custom-chat-history-button" onClick={handleGoToChatHistory}>Chat History</button>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div>
            {selectedTicket ? (
                renderTicketDetails()
            ) : (
                <div>Select a ticket to view details</div>
            )}
        </div>
    );
};

export default Tickets;
