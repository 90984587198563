import React from 'react';
import DailyReportContent from './DailyReportContent';
import '../../../../css/sohopizza/dailyReport.css';

const DailyReport = ({ date, reportType, handleGoToTicket }) => {
    return (
        <div className="daily-report-container">
            <DailyReportContent date={date} reportType={reportType} handleGoToTicket={handleGoToTicket} />
        </div>
    );
};

export default DailyReport;
