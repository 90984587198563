import React from 'react';
import { FaArrowLeft, FaTicketAlt } from 'react-icons/fa';
import { formatTimestamp } from '../../../../utils';

const ChatHistoriesSidebar = ({ date, setDate, chatHistories, selectedTicket, handleSelectItem, setActiveTab, tickets }) => {
    const checkTicketExists = (customerName, phoneNumber, date) => {
        return tickets.some(ticket => {
            return ticket.data.customer_name === customerName && ticket.data.phone_number === phoneNumber && ticket.data.date_time.startsWith(date);
        });
    };

    return (
        <>
            <div className="sidebar-header">
                <button onClick={() => setActiveTab('main')} className="back-button"><FaArrowLeft /></button>
                <input type="date" value={date} onChange={e => setDate(e.target.value)} className="custom-date-picker" />
            </div>
            <div className="scrollable-content">
                {chatHistories.length === 0 ? <div>No data</div> : chatHistories.map((history, index) => {
                    const [customerName, phoneNumber] = history.id.split('_');
                    const lastMessage = history.data.messages[history.data.messages.length - 1];
                    const formattedTime = formatTimestamp(lastMessage.timestamp);
                    const ticketExists = checkTicketExists(customerName, phoneNumber, date);

                    return (
                        <button key={index} className={selectedTicket === history.id ? 'active' : ''} onClick={() => handleSelectItem(history.id)}>
                            <div className="chat-info">
                                <div className="chat-label">
                                    {customerName}
                                    {ticketExists && <FaTicketAlt className="ticket-icon" />}
                                </div>
                                <small className="chat-number">
                                    {phoneNumber} - {formattedTime}
                                </small>
                            </div>
                        </button>
                    );
                })}
            </div>
        </>
    );
};

export default ChatHistoriesSidebar;
