import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../context/AuthContext';
import { initializeSohoPizzaApp } from '../../../firebase';
import { collection, getFirestore, onSnapshot } from 'firebase/firestore';
import MainSidebar from './MainSidebar';
import TicketsSidebar from './Tickets/TicketsSidebar';
import ChatHistoriesSidebar from './ChatHistories/ChatHistoriesSidebar';
import Tickets from './Tickets/Tickets';
import ChatHistories from './ChatHistories/ChatHistories';
import Drivers from './Drivers/Drivers';
import DailyReport from './Reports/DailyReport';
import DailyReportSidebar from './Reports/DailyReportSidebar';
import MonthlyReport from './Reports/MonthlyReport';
import MonthlyReportSidebar from './Reports/MonthlyReportSidebar';
import '../../../css/user.css';
import { FaSignOutAlt, FaBars } from 'react-icons/fa';
import WelcomeScreen from "./WelcomeScreen";

const sohoPizzaApp = initializeSohoPizzaApp();
const sohoPizzaDb = getFirestore(sohoPizzaApp);

const RestaurantUser = () => {
    const { user, logout } = useAuth();
    const [activeTab, setActiveTab] = useState('main');
    const [tickets, setTickets] = useState([]);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [chatHistories, setChatHistories] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
    const [reportType, setReportType] = useState('');
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(null);

    const getNYDate = () => {
        const now = new Date();
        const offset = now.getTimezoneOffset() * 60000;
        const newYorkTime = new Date(now.getTime() + offset + (-5 * 3600000));
        if (newYorkTime.getHours() < 6) {
            newYorkTime.setDate(newYorkTime.getDate() - 1);
        }
        return newYorkTime.toISOString().split('T')[0];
    };

    const [date, setDate] = useState(getNYDate());
    const [notPaidDeliveryTickets, setNotPaidDeliveryTickets] = useState([]);

    const getFormattedCompany = () => {
        return user?.company.split(' ')[0].toUpperCase();
    };

    const fetchTickets = async () => {
        try {
            const formattedCompany = getFormattedCompany();
            const paths = [
                `${formattedCompany}/date/${date}/pickup/tickets`,
                `${formattedCompany}/date/${date}/delivery/tickets`
            ];
            const ticketsData = { pickup: [], delivery: [] };

            const unsubscribes = paths.map(path => {
                return onSnapshot(collection(sohoPizzaDb, path), snapshot => {
                    snapshot.docChanges().forEach(change => {
                        if (change.type === 'added' || change.type === 'modified') {
                            const data = change.doc.data();
                            const ticketType = path.includes('pickup') ? 'pickup' : 'delivery';
                            ticketsData[ticketType].push({
                                id: `${ticketType}_${change.doc.id}`,
                                type: ticketType,
                                data: {
                                    ...data,
                                    order_total_before_tax: data.total_before_tax,
                                    order_total_after_tax: data["FINAL TOTAL"],
                                    payment_status: data["Payment Status"]
                                }
                            });
                        }
                    });

                    const sortedTickets = [...ticketsData.pickup, ...ticketsData.delivery].sort((a, b) => {
                        const dateA = new Date(a.data.date_time || a.data.created_at.seconds * 1000);
                        const dateB = new Date(b.data.date_time || b.data.created_at.seconds * 1000);
                        return dateB - dateA;
                    });

                    setTickets(sortedTickets);
                });
            });

            return () => unsubscribes.forEach(unsub => unsub());
        } catch (error) {
            console.error('Error fetching tickets:', error);
        }
    };

    useEffect(() => {
        fetchTickets();
    }, [date]);

    useEffect(() => {
        const fetchChatHistories = async () => {
            if (activeTab === 'ChatHistories') {
                try {
                    const formattedCompany = getFormattedCompany();
                    const path = `${formattedCompany}/date/${date}/chat_history/chat_history`;
                    const unsubscribe = onSnapshot(collection(sohoPizzaDb, path), snapshot => {
                        const chatHistoriesData = snapshot.docs.map(doc => {
                            const data = doc.data();
                            return {
                                id: doc.id,
                                data: {
                                    ...data
                                }
                            };
                        });

                        const sortedChatHistories = chatHistoriesData.sort((a, b) => {
                            const dateA = new Date(`1970-01-01T${a.data.messages[a.data.messages.length - 1].timestamp}Z`);
                            const dateB = new Date(`1970-01-01T${b.data.messages[b.data.messages.length - 1].timestamp}Z`);
                            return dateB - dateA;
                        });

                        setChatHistories(sortedChatHistories);
                    });

                    return () => unsubscribe();
                } catch (error) {
                    console.error('Error fetching chat histories:', error);
                }
            }
        };

        const fetchNotPaidDeliveryTickets = async () => {
            if (activeTab === 'Drivers') {
                try {
                    const formattedCompany = getFormattedCompany();
                    const path = `${formattedCompany}/date/${date}/delivery/tickets`;
                    const unsubscribe = onSnapshot(collection(sohoPizzaDb, path), snapshot => {
                        const ticketsData = snapshot.docs
                            .map(doc => ({ id: doc.id, ...doc.data() }))
                            .filter(ticket => ticket['Payment Status'] === 'NOT-PAID');

                        setNotPaidDeliveryTickets(ticketsData);
                    });

                    return () => unsubscribe();
                } catch (error) {
                    console.error('Error fetching tickets:', error);
                }
            }
        };

        if (activeTab === 'Tickets') {
            fetchTickets();
        } else if (activeTab === 'ChatHistories') {
            fetchChatHistories();
        } else if (activeTab === 'Drivers') {
            fetchNotPaidDeliveryTickets();
        }
    }, [date, activeTab]);

    const handleSidebarToggle = () => {
        if (window.innerWidth <= 768) {
            setIsMobileSidebarOpen(!isMobileSidebarOpen);
        } else {
            setSidebarOpen(!sidebarOpen);
        }
    };

    const handleSelectItem = (id) => {
        setSelectedTicket(id);
        if (window.innerWidth <= 768) {
            setIsMobileSidebarOpen(false);
        }
    };

    const handleGoToTicket = (customerName, date) => {
        console.log('Finding ticket for:', customerName, date);

        const ticket = tickets.find(t => {
            return t.data.customer_name === customerName && t.data.date_time.startsWith(date);
        });

        if (ticket) {
            console.log('Ticket found:', ticket);
            setDate(date); // Tarihi güncelle
            setSelectedTicket(ticket.id);
            setActiveTab('Tickets');
        } else {
            console.error('Ticket not found for:', customerName, date);
        }
    };

    const renderContent = () => {
        switch (activeTab) {
            case 'Tickets':
                return <Tickets tickets={tickets} date={date} setDate={setDate} selectedTicket={selectedTicket} handleSelectItem={handleSelectItem} setActiveTab={setActiveTab} />;
            case 'ChatHistories':
                return <ChatHistories chatHistories={chatHistories} date={date} setDate={setDate} selectedTicket={selectedTicket} handleSelectItem={handleSelectItem} setActiveTab={setActiveTab} tickets={tickets} handleGoToTicket={handleGoToTicket} />;
            case 'Drivers':
                return <Drivers drivers={drivers} />;
            case 'DailyReport':
                return <DailyReport date={date} setDate={setDate} reportType={reportType} handleGoToTicket={handleGoToTicket} />;
            case 'MonthlyReport':
                return <MonthlyReport selectedMonth={selectedMonth} />;
            default:
                return <WelcomeScreen />;
        }
    };

    return (
        <div className={`custom-user-container ${activeTab === 'ChatHistories' ? 'custom-no-padding' : ''}`}>
            <div className={`custom-sidebar custom-main-sidebar ${sidebarOpen ? '' : 'collapsed'} ${isMobileSidebarOpen ? 'open' : ''}`}>
                <div className="header-section">
                    <button className="toggle-sidebar" onClick={handleSidebarToggle}>
                        <FaBars />
                        {sidebarOpen && <span className="sidebar-label">{activeTab === 'main' ? "" : activeTab}</span>}
                    </button>
                </div>
                {sidebarOpen && (
                    <div className="scrollable-tabs">
                        {activeTab !== 'Tickets' && activeTab !== 'ChatHistories' && activeTab !== 'DailyReport' && activeTab !== 'MonthlyReport' ? (
                            <MainSidebar activeTab={activeTab} setActiveTab={setActiveTab} />
                        ) : (
                            <>
                                {activeTab === 'Tickets' && <TicketsSidebar date={date} setDate={setDate} tickets={tickets} selectedTicket={selectedTicket} handleSelectItem={handleSelectItem} setActiveTab={setActiveTab} />}
                                {activeTab === 'ChatHistories' && <ChatHistoriesSidebar date={date} setDate={setDate} chatHistories={chatHistories} selectedTicket={selectedTicket} handleSelectItem={handleSelectItem} setActiveTab={setActiveTab} tickets={tickets} handleGoToTicket={handleGoToTicket} />}
                                {activeTab === 'DailyReport' && <DailyReportSidebar date={date} setDate={setDate} setActiveTab={setActiveTab} reportType={reportType} setReportType={setReportType} />}
                                {activeTab === 'MonthlyReport' && (
                                    <MonthlyReportSidebar
                                        selectedYear={selectedYear}
                                        setSelectedYear={setSelectedYear}
                                        setSelectedMonth={setSelectedMonth}
                                        setActiveTab={setActiveTab}
                                    />
                                )}
                            </>
                        )}
                    </div>
                )}
                <div className="footer-section">
                    <div className="custom-user-info">
                        <span>{user?.email}</span>
                        <button className="custom-logout-button" onClick={logout}><FaSignOutAlt /> Logout</button>
                    </div>
                </div>
            </div>
            <div className="custom-content custom-main-content">
                {renderContent()}
            </div>
            {window.innerWidth <= 768 && !isMobileSidebarOpen && (
                <button className="mobile-sidebar-toggle" onClick={handleSidebarToggle}>
                    <FaBars />
                </button>
            )}
        </div>
    );
};

export default RestaurantUser;
export { sohoPizzaDb };
