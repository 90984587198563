import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { getDocs, collection, getFirestore } from 'firebase/firestore';
import { initializeSohoPizzaApp } from '../../../../firebase';
import { useAuth } from '../../../../context/AuthContext';

const sohoPizzaApp = initializeSohoPizzaApp();
const sohoPizzaDb = getFirestore(sohoPizzaApp);

const MonthlyReportSidebar = ({ selectedYear, setSelectedYear, setSelectedMonth, setActiveTab }) => {
    const { user } = useAuth();
    const [months, setMonths] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const getFormattedCompany = () => {
        return user?.company.split(' ')[0].toUpperCase();
    };

    useEffect(() => {
        const fetchMonths = async () => {
            setLoading(true);
            setError('');
            setMonths([]);

            const formattedCompany = getFormattedCompany();
            const path = `${formattedCompany}/other/MONTHLY_REPORTS`;
            const collectionRef = collection(sohoPizzaDb, path);

            try {
                const querySnapshot = await getDocs(collectionRef);
                const monthDocs = querySnapshot.docs
                    .map(doc => doc.id)
                    .filter(id => id.endsWith(`-${selectedYear}`));

                if (monthDocs.length > 0) {
                    setMonths(monthDocs.sort().reverse());
                } else {
                    setError('No reports available for the selected year.');
                }
            } catch (err) {
                setError('Error fetching months.');
                console.error(err);
            }

            setLoading(false);
        };

        fetchMonths();
    }, [selectedYear]);

    const handleYearChange = (change) => {
        setSelectedYear((prevYear) => {
            const newYear = parseInt(prevYear, 10) + change;
            return newYear.toString();
        });
    };

    return (
        <>
            <div className="sidebar-header">
                <button onClick={() => setActiveTab('main')} className="back-button"><FaArrowLeft /></button>
                <div className="custom-year-picker-container">
                    <button onClick={() => handleYearChange(-1)} className="year-button">
                        <FaChevronLeft />
                    </button>
                    <input
                        type="text"
                        value={selectedYear}
                        readOnly
                        className="custom-year-picker"
                    />
                    <button onClick={() => handleYearChange(1)} className="year-button">
                        <FaChevronRight />
                    </button>
                </div>
            </div>
            <div className="scrollable-content">
                {loading ? (
                    <p>Loading...</p>
                ) : (
                    <>
                        {error && <p>{error}</p>}
                        {months.map(month => (
                            <button key={month} onClick={() => setSelectedMonth(month)}>
                                {month.split('-')[0]}
                            </button>
                        ))}
                    </>
                )}
            </div>
        </>
    );
};

export default MonthlyReportSidebar;
