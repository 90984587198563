import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../../context/AuthContext';
import { doc, onSnapshot } from 'firebase/firestore';
import robotImage from '../../../../assets/robot.jpg';
import { formatTimestamp, linkify } from '../../../../utils';
import '../../../../css/sohopizza/chatHistories.css';
import { sohoPizzaDb } from '../RestaurantUser';

const ChatHistory = ({ selectedTicket, date, tickets, handleGoToTicket, customerName, phoneNumber }) => {
    const { user } = useAuth();
    const [messages, setMessages] = useState([]);

    const getFormattedCompany = () => {
        return user?.company.split(' ')[0].toUpperCase();
    };

    useEffect(() => {
        if (selectedTicket) {
            const fetchChatHistory = async () => {
                const formattedCompany = getFormattedCompany();
                const path = `${formattedCompany}/date/${date}/chat_history/chat_history/${selectedTicket}`;
                try {
                    const unsubscribe = onSnapshot(doc(sohoPizzaDb, path), docSnap => {
                        if (docSnap.exists()) {
                            const data = docSnap.data();
                            setMessages(data.messages);
                        } else {
                            console.error("No such document!");
                        }
                    });

                    return () => unsubscribe();
                } catch (error) {
                    console.error('Error fetching chat history:', error);
                }
            };
            fetchChatHistory();
        }
    }, [selectedTicket, date]);

    const checkTicketExists = (customerName, phoneNumber, date) => {
        const exists = tickets.some(ticket => {
            return ticket.data.customer_name === customerName && ticket.data.phone_number === phoneNumber && ticket.data.date_time.startsWith(date);
        });
        console.log(`Check ticket exists for ${customerName}, ${phoneNumber}, ${date}: ${exists}`);
        return exists;
    };

    const renderMessages = () => {
        if (messages.length === 0) {
            return <div>No messages found.</div>;
        }

        const sortedMessages = [...messages].sort((a, b) => {
            const dateA = new Date(`1970-01-01T${a.timestamp}Z`);
            const dateB = new Date(`1970-01-01T${b.timestamp}Z`);
            return dateA - dateB;
        });

        console.log(`Customer name: ${customerName}, Phone number: ${phoneNumber}`);

        const ticketExists = checkTicketExists(customerName, phoneNumber, date);

        return (
            <>
                {sortedMessages.map((message, index) => (
                    <div key={index} className="custom-chat-wrapper">
                        <div className="custom-customer-message-container">
                            <div className="custom-message custom-customer-message">
                                <p className="message-text">{message.customer_message}</p>
                                <p className="message-time">{formatTimestamp(message.timestamp)}</p>
                            </div>
                        </div>
                        <div className="custom-response-message-container">
                            <img src={robotImage} className="custom-profile-picture" alt="Profile" />
                            <div className="custom-message custom-response-message"
                                 dangerouslySetInnerHTML={{ __html: linkify(message.response_message) }}>
                            </div>
                        </div>
                    </div>
                ))}
                {ticketExists && (
                    <button className="chatHistoriesTicketButton" onClick={() => handleGoToTicket(customerName, date)}>Go to Ticket</button>
                )}
            </>
        );
    };

    return (
        <div className="custom-single-chat-history">
            {renderMessages()}
        </div>
    );
};

export default ChatHistory;
