import React, { useState, useEffect } from 'react';
import { collection, getDocs, getFirestore, doc, updateDoc, onSnapshot, setDoc, getDoc } from 'firebase/firestore';
import { initializeSohoPizzaApp } from '../../../../firebase';
import { useAuth } from '../../../../context/AuthContext';
import DriverList from './DriverList';
import DriverTicketList from './DriverTicketList';
import '../../../../css/sohopizza/drivers.css';

const sohoPizzaApp = initializeSohoPizzaApp();
const sohoPizzaDb = getFirestore(sohoPizzaApp);

const getNYDate = () => {
    const now = new Date();
    const offset = now.getTimezoneOffset() * 60000;
    const newYorkTime = new Date(now.getTime() + offset + (-5 * 3600000));
    if (newYorkTime.getHours() < 6) {
        newYorkTime.setDate(newYorkTime.getDate() - 1);
    }
    return newYorkTime.toISOString().split('T')[0];
};

const getNYTime = () => {
    const now = new Date();
    const offset = now.getTimezoneOffset() * 60000;
    return new Date(now.getTime() + offset + (-5 * 3600000));
};

const Drivers = ({ drivers }) => {
    const { user } = useAuth();
    const [driversList, setDriversList] = useState(drivers);
    const [notPaidDeliveryTickets, setNotPaidDeliveryTickets] = useState([]);
    const [selectedTickets, setSelectedTickets] = useState([]);
    const [selectedDate, setSelectedDate] = useState(getNYDate());
    const [showActiveDrivers, setShowActiveDrivers] = useState(false);
    const [selectedDriver, setSelectedDriver] = useState(null);

    const getFormattedCompany = () => {
        return user?.company.split(' ')[0].toUpperCase();
    };

    const getFormattedCompanyForPath = () => {
        return user?.company.replace(/\s+/g, '');
    };

    const closeDayForDriver = async (driver) => {
        const today = getNYDate();
        const reportPath = `companies/${getFormattedCompanyForPath()}/${driver.name.replace(/\s+/g, '_')}_reports/${today}`;
        const docRef = doc(sohoPizzaDb, reportPath);

        try {
            await setDoc(docRef, { tickets: driver.tickets });
            const driverDocRef = doc(sohoPizzaDb, `companies/${getFormattedCompanyForPath()}/drivers`, driver.id);
            await updateDoc(driverDocRef, { tickets: [], isActive: false, isClosed: true });
            console.log(`Day closed for driver ${driver.name}`);
        } catch (error) {
            console.error('Failed to close day for driver:', driver.name, error);
        }
    };

    const checkAndCloseDay = async () => {
        const now = getNYTime();
        if (now.getHours() === 6) {  // Saat tam 6 ise
            driversList.forEach(driver => {
                if (driver.tickets && driver.tickets.length > 0 && !driver.isClosed) {
                    closeDayForDriver(driver);
                }
            });
            // Yeni gün başladığında isClosed değerlerini false yap
            await resetDriversForNewDay();
        }
    };

    const resetDriversForNewDay = async () => {
        const collectionRef = collection(sohoPizzaDb, `companies/${getFormattedCompanyForPath()}/drivers`);
        const querySnapshot = await getDocs(collectionRef);
        const driversData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        await Promise.all(driversData.map(async driver => {
            const driverDocRef = doc(sohoPizzaDb, `companies/${getFormattedCompanyForPath()}/drivers`, driver.id);
            await updateDoc(driverDocRef, { isClosed: false });
        }));

        setDriversList(driversData.map(driver => ({ ...driver, isClosed: false })));
        console.log("Drivers reset for new day");
    };

    useEffect(() => {
        const interval = setInterval(checkAndCloseDay, 1800000); // Check every 30 minutes
        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [driversList]);

    useEffect(() => {
        const fetchNotPaidDeliveryTickets = async () => {
            try {
                const date = selectedDate;
                const formattedCompany = getFormattedCompany();
                const path = `${formattedCompany}/date/${date}/delivery/tickets`;
                const collectionRef = collection(sohoPizzaDb, path);

                const unsubscribe = onSnapshot(collectionRef, snapshot => {
                    const ticketsData = snapshot.docs
                        .map(doc => ({ id: doc.id, ...doc.data() }))
                        .filter(ticket => ticket['Payment Status'] === 'NOT-PAID' && ticket.isAssigned !== true);

                    setNotPaidDeliveryTickets(ticketsData);
                });

                return () => unsubscribe(); // Clean up the subscription on unmount
            } catch (error) {
                console.error('Error fetching tickets:', error);
            }
        };

        fetchNotPaidDeliveryTickets();
    }, [selectedDate]);

    useEffect(() => {
        const fetchDrivers = async () => {
            try {
                const collectionRef = collection(sohoPizzaDb, `companies/${getFormattedCompanyForPath()}/drivers`);
                const querySnapshot = await getDocs(collectionRef);
                const driversData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setDriversList(driversData);
            } catch (error) {
                console.error('Error fetching drivers:', error);
            }
        };

        fetchDrivers();
    }, []);

    const handleTicketSelection = (ticketId) => {
        setSelectedTickets(prevSelected =>
            prevSelected.includes(ticketId)
                ? prevSelected.filter(id => id !== ticketId)
                : [...prevSelected, ticketId]
        );
    };

    const handleSelectAllTickets = () => {
        if (selectedTickets.length === notPaidDeliveryTickets.length) {
            setSelectedTickets([]);
        } else {
            setSelectedTickets(notPaidDeliveryTickets.map(ticket => ticket.id));
        }
    };

    const handleAssignTickets = () => {
        setShowActiveDrivers(!showActiveDrivers);
    };

    const assignTicketsToDriver = async (driverId) => {
        if (selectedTickets.length === 0) {
            alert('No tickets selected.');
            return;
        }

        const driverDocRef = doc(sohoPizzaDb, `companies/${getFormattedCompanyForPath()}/drivers`, driverId);
        const driverDoc = await getDoc(driverDocRef);
        const driver = driverDoc.data();

        if (!driver.isActive) {
            alert('Driver is not active.');
            return;
        }

        const today = getNYDate();
        const isCurrentDate = selectedDate === today;

        if (!isCurrentDate) {
            alert('Cannot assign tickets for past dates.');
            return;
        }

        const validTickets = selectedTickets.map(ticketId => {
            const ticket = notPaidDeliveryTickets.find(ticket => ticket.id === ticketId);
            if (ticket) {
                return {
                    id: `Delivery_${ticket.ticket_number}`,
                    customer_name: ticket.customer_name,
                    address: ticket.address,
                    final_total: ticket['FINAL TOTAL']
                };
            }
            return null;
        }).filter(ticket => ticket !== null);

        if (validTickets.length === 0) {
            alert('No valid tickets to assign.');
            return;
        }

        const newTickets = validTickets.filter(newTicket => {
            return !((driver.tickets || []).some(existingTicket => existingTicket.id === newTicket.id));
        });

        const updatedDriver = {
            ...driver,
            tickets: [
                ...(driver.tickets || []),
                ...newTickets
            ]
        };

        await updateDoc(driverDocRef, { tickets: updatedDriver.tickets });

        await Promise.all(selectedTickets.map(async (ticketId) => {
            const formattedCompany = getFormattedCompany();
            const ticketDocRef = doc(sohoPizzaDb, `${formattedCompany}/date/${selectedDate}/delivery/tickets`, ticketId);
            await updateDoc(ticketDocRef, { isAssigned: true });
        }));

        const collectionRef = collection(sohoPizzaDb, `companies/${getFormattedCompanyForPath()}/drivers`);
        const querySnapshot = await getDocs(collectionRef);
        const driversData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setDriversList(driversData);

        setNotPaidDeliveryTickets(prevTickets => prevTickets.filter(ticket => !selectedTickets.includes(ticket.id)));
        setSelectedTickets([]);
        setShowActiveDrivers(false);
    };

    return (
        <div className="drivers-and-tickets-container">
            <DriverTicketList
                notPaidDeliveryTickets={notPaidDeliveryTickets}
                selectedTickets={selectedTickets}
                handleTicketSelection={handleTicketSelection}
                handleSelectAllTickets={handleSelectAllTickets}
                handleAssignTickets={handleAssignTickets}
                showActiveDrivers={showActiveDrivers}
                assignTicketsToDriver={assignTicketsToDriver}
                driversList={driversList}
                setSelectedDate={setSelectedDate}
            />
            <DriverList
                selectedDate={selectedDate}
                notPaidDeliveryTickets={notPaidDeliveryTickets}
                setNotPaidDeliveryTickets={setNotPaidDeliveryTickets}
                setDriversList={setDriversList}
                setSelectedDriver={setSelectedDriver}
            />
        </div>
    );
};

export default Drivers;
