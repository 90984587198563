import React from 'react';
import ChatHistory from './ChatHistory';
import '../../../../css/sohopizza/chatHistories.css';

const ChatHistories = ({ chatHistories, date, setDate, selectedTicket, handleSelectItem, tickets, handleGoToTicket }) => {
    const [customerName, phoneNumber] = selectedTicket ? selectedTicket.split('_') : ['', ''];

    return (
        <div className="custom-chat-histories-wrapper">
            {selectedTicket ? (
                <ChatHistory
                    selectedTicket={selectedTicket}
                    date={date}
                    tickets={tickets}
                    handleGoToTicket={handleGoToTicket}
                    customerName={customerName}
                    phoneNumber={phoneNumber}
                />
            ) : (
                <div className="custom-chat-histories-header">Select a chat history to view details</div>
            )}
        </div>
    );
};

export default ChatHistories;
