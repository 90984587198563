import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, deleteDoc, addDoc, updateDoc, getDoc } from 'firebase/firestore';
import { useAuth } from '../../../../context/AuthContext';
import DriverItem from './DriverItem';
import DriverModal from './DriverModal';
import { FaPlus } from 'react-icons/fa';
import { sohoPizzaDb } from '../RestaurantUser';

const DriverList = ({ selectedDate, notPaidDeliveryTickets, setNotPaidDeliveryTickets }) => {
    const { user } = useAuth();
    const [driversList, setDriversList] = useState([]);
    const [selectedDriver, setSelectedDriver] = useState(null);
    const [newDriverName, setNewDriverName] = useState('');
    const [isAddDriverOpen, setIsAddDriverOpen] = useState(false);

    const getFormattedCompanyForPath = () => {
        return user?.company.replace(/\s+/g, '');
    };

    const fetchDrivers = async () => {
        try {
            const collectionRef = collection(sohoPizzaDb, `companies/${getFormattedCompanyForPath()}/drivers`);
            const querySnapshot = await getDocs(collectionRef);
            const driversData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setDriversList(driversData);
        } catch (error) {
            console.error('Error fetching drivers:', error);
        }
    };

    useEffect(() => {
        fetchDrivers();
    }, []);

    const handleAddDriver = async () => {
        if (newDriverName.trim() === '') {
            alert('Driver name cannot be empty.');
            return;
        }
        const collectionRef = collection(sohoPizzaDb, `companies/${getFormattedCompanyForPath()}/drivers`);
        await addDoc(collectionRef, { name: newDriverName, isActive: false, isClosed: false });
        setNewDriverName('');
        await fetchDrivers();  // Güncellenmiş sürücü listesini çek
    };

    const handleDeleteDriver = async (id) => {
        try {
            const docRef = doc(sohoPizzaDb, `companies/${getFormattedCompanyForPath()}/drivers`, id);
            await deleteDoc(docRef);
            await fetchDrivers();  // Güncellenmiş sürücü listesini çek
        } catch (error) {
            console.error('Error deleting driver:', error);
        }
    };

    const toggleDriverStatus = async (id, currentStatus, date) => {
        const today = new Date().toISOString().substr(0, 10);
        if (date < today) {
            alert('Cannot change driver status for past dates.');
            return;
        }

        const docRef = doc(sohoPizzaDb, `companies/${getFormattedCompanyForPath()}/drivers`, id);
        await updateDoc(docRef, { isActive: !currentStatus });

        await fetchDrivers();  // Güncellenmiş sürücü listesini çek
    };

    const handleDriverClick = async (driver) => {
        const docRef = doc(sohoPizzaDb, `companies/${getFormattedCompanyForPath()}/drivers`, driver.id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const driverData = docSnap.data();
            if (driverData.isClosed || selectedDate < new Date().toISOString().substr(0, 10)) {
                const reportPath = `companies/${getFormattedCompanyForPath()}/${driver.name.replace(/\s+/g, '_')}_reports/${selectedDate}`;
                const reportDocRef = doc(sohoPizzaDb, reportPath);
                const reportDoc = await getDoc(reportDocRef);
                if (reportDoc.exists()) {
                    setSelectedDriver({ ...driver, tickets: [], report: reportDoc.data().tickets || [] });
                } else {
                    setSelectedDriver({ ...driver, tickets: [], report: [] });
                }
            } else {
                setSelectedDriver({ ...driver, tickets: driverData.tickets || [] });
            }
        } else {
            console.log("No such document!");
        }
    };

    return (
        <div className="drivers-container">
            <div className="drivers-header">
                <h3>Drivers</h3>
                <button onClick={() => setIsAddDriverOpen(!isAddDriverOpen)} className="add-driver-toggle">
                    <FaPlus /> Add Driver
                </button>
            </div>
            {isAddDriverOpen && (
                <div className="add-driver">
                    <input
                        type="text"
                        placeholder="Driver Name"
                        value={newDriverName}
                        onChange={(e) => setNewDriverName(e.target.value)}
                    />
                    <button onClick={handleAddDriver}><FaPlus /></button>
                </div>
            )}
            <ul className="drivers-list">
                {driversList.map(driver => (
                    <DriverItem
                        key={driver.id}
                        driver={driver}
                        selectedDate={selectedDate}
                        onDriverClick={handleDriverClick}
                        onDeleteDriver={handleDeleteDriver}
                        onToggleDriverStatus={toggleDriverStatus}
                    />
                ))}
            </ul>
            {selectedDriver && (
                <DriverModal
                    selectedDriver={selectedDriver}
                    onClose={() => setSelectedDriver(null)}
                    notPaidDeliveryTickets={notPaidDeliveryTickets}
                    setNotPaidDeliveryTickets={setNotPaidDeliveryTickets}
                    driversList={driversList}
                    setDriversList={setDriversList}
                    selectedDate={selectedDate}
                    setSelectedDriver={setSelectedDriver}
                />
            )}
        </div>
    );
};

export default DriverList;
