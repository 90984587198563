import React from 'react';
import { useAuth } from '../../context/AuthContext';
import '../../css/user.css';
import { FaSignOutAlt } from 'react-icons/fa';

function DefaultView() {
    const { user, logout } = useAuth();

    return (
        <div className="user-container">
            <div className="sidebar main-sidebar">
                <div className="user-info">
                    <span>{user?.email}</span>
                    <button className="logout-button" onClick={logout}><FaSignOutAlt /> Çıkış Yap</button>
                </div>
            </div>
            <div className="content main-content">
                <h2>Hoşgeldiniz!</h2>
                <p>Varsayılan görünüm.</p>
            </div>
        </div>
    );
}

export default DefaultView;
