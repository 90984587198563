import React, { useState } from 'react';
import { registerUser } from '../../utils';

const UserForm = ({ setActiveTab }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [companyType, setCompanyType] = useState('restaurant');
    const [company, setCompany] = useState('');
    const [role, setRole] = useState('user');
    const [isRoleUser, setIsRoleUser] = useState(true);

    const companyOptions = {
        restaurant: ['Soho Pizza', 'Noho Pizza'],
        technology: ['Ai Bunny']
    };

    const handleRoleChange = (e) => {
        setRole(e.target.value);
        setIsRoleUser(e.target.value === 'user');
        if (e.target.value !== 'user') {
            setCompany('');
            setCompanyType('');
        }
    };

    const handleCompanyTypeChange = (e) => {
        setCompanyType(e.target.value);
        setCompany(companyOptions[e.target.value][0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await registerUser(email, password, company, companyType, role);
        setEmail('');
        setPassword('');
        setCompany('');
        setCompanyType('restaurant');
        setRole('user');
        setIsRoleUser(true);
        setActiveTab('Users');
    };

    return (
        <div className="user-form-container">
            <form onSubmit={handleSubmit} className="user-form">
                <h2 className="user-form-header">Add New User</h2>
                <div className="form-group">
                    <label>Email</label>
                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required className="form-input" />
                </div>
                <div className="form-group">
                    <label>Password</label>
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required className="form-input" />
                </div>
                <div className="form-group">
                    <label>Role</label>
                    <select value={role} onChange={handleRoleChange} required className="form-input">
                        <option value="user">User</option>
                        <option value="admin">Admin</option>
                    </select>
                </div>
                {isRoleUser && (
                    <>
                        <div className="form-group">
                            <label>Company Type</label>
                            <select value={companyType} onChange={handleCompanyTypeChange} required className="form-input">
                                <option value="restaurant">Restaurant</option>
                                <option value="technology">Technology</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Company</label>
                            <select value={company} onChange={(e) => setCompany(e.target.value)} required className="form-input">
                                {companyOptions[companyType].map((comp) => (
                                    <option key={comp} value={comp}>{comp}</option>
                                ))}
                            </select>
                        </div>
                    </>
                )}
                <button type="submit" className="user-form-button">Add User</button>
            </form>
        </div>
    );
};

export default UserForm;
