import React from 'react';
import { FaTicketAlt, FaTruck, FaUserAlt, FaCogs, FaChartBar } from 'react-icons/fa'; // FaChartBar eklendi

const MainSidebar = ({ activeTab, setActiveTab }) => (
    <>
            <button className={activeTab === 'Tickets' ? 'active' : ''} onClick={() => setActiveTab('Tickets')}>
                    <FaTicketAlt/> Tickets
            </button>
            <button className={activeTab === 'ChatHistories' ? 'active' : ''}
                    onClick={() => setActiveTab('ChatHistories')}><FaUserAlt/> Chat Histories
            </button>
            <button className={activeTab === 'Drivers' ? 'active' : ''} onClick={() => setActiveTab('Drivers')}>
                    <FaTruck/> Drivers
            </button>
            <button className={activeTab === 'DailyReport' ? 'active' : ''}
                    onClick={() => setActiveTab('DailyReport')}><FaChartBar/> Daily Report
            </button>
            <button className={activeTab === 'MonthlyReport' ? 'active' : ''}
                    onClick={() => setActiveTab('MonthlyReport')}><FaChartBar/> Monthly Report
            </button>
    </>
);

export default MainSidebar;
