import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { HashLink as HLink } from 'react-router-hash-link';

class Header extends Component {
   componentDidMount() {
      if (this.props.alwaysVisible) {
         window.scrollTo(0, 0); // Scroll to the top when the page loads
      }
   }

   render() {
      let imgattr = 'image';
      let { alwaysVisible } = this.props;

      return (
          <div>
             <header id="home" className={alwaysVisible ? 'visible-header' : ''}>
                <nav className={`navbar navbar-inverse navbar-expand-lg header-nav fixed-top light-header ${alwaysVisible ? 'gradient-bg' : ''}`}>
                   <div className="container">
                      <a className="navbar-brand" href={`${process.env.PUBLIC_URL}/`}>
                         <img src={process.env.PUBLIC_URL + '/assets/img/logo.png'} alt={imgattr} style={{ width: '250px', height: 'auto' }} // Adjust width and height as needed
    />
                      </a>
                      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCodeply">
                         <i className="icofont-navigation-menu"></i>
                      </button>
                      <div className="collapse navbar-collapse" id="navbarCodeply">
                         <ul className="nav navbar-nav ml-auto">
                            <li><HLink className="nav-link" smooth to={`/#home`}>Home</HLink></li>
                            <li><HLink className="nav-link" smooth to={`/#services`}>Services</HLink></li>
                            <li><HLink className="nav-link" smooth to={`/#pricing`}>Pricing</HLink></li>
                            <li><HLink className="nav-link" smooth to={`/#feature`}>Example Case</HLink></li>
                            <li><HLink className="nav-link" smooth to={`/#contact`}>Contact</HLink></li>
                            <li><Link className="nav-link" to="/login">User Panel</Link></li> {/* Added user panel link */}
                         </ul>
                      </div>
                   </div>
                </nav>
             </header>
          </div>
      );
   }
}

export default Header;
