import React, { useState, useEffect } from 'react';
import './../../css/Notification.css';


const Notification = ({ notification, onClose }) => {
    useEffect(() => {
        if (notification.message) {
            const timer = setTimeout(() => {
                onClose();
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [notification, onClose]);

    if (!notification.message) return null;

    return (
        <div className={`notification ${notification.type}`}>
            {notification.message}
        </div>
    );
};

export const notifySuccess = (setNotification, message) => {
    setNotification({ message, type: 'success' });
};

export const notifyError = (setNotification, message) => {
    setNotification({ message, type: 'error' });
};

export default Notification;