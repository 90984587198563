import React, { createContext, useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { auth, db, initializeSohoPizzaApp } from '../firebase';
import { getUserData } from '../utils';

const AuthContext = createContext();

export function AuthProvider({ children }) {
    const [user, setUser] = useState(null);
    const [dbInstance, setDbInstance] = useState(db);
    const history = useHistory();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
            if (firebaseUser) {
                try {
                    const userData = await getUserData(firebaseUser.uid);
                    if (userData && userData.role) {
                        setUser({ ...firebaseUser, ...userData });

                        if (userData.company === 'Soho Pizza') {
                            const sohoApp = initializeSohoPizzaApp();
                            setDbInstance(getFirestore(sohoApp));
                        }
                    } else {
                        console.error('User data not found or role information missing!');
                        setUser(null);
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                    setUser(null);
                }
            } else {
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, []);

    const login = async (email, password) => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const firebaseUser = userCredential.user;

            const userData = await getUserData(firebaseUser.uid);
            if (userData && userData.role) {
                setUser({ ...firebaseUser, ...userData });

                if (userData.company === 'Soho Pizza') {
                    const sohoApp = initializeSohoPizzaApp();
                    setDbInstance(getFirestore(sohoApp));
                }

                if (userData.role === 'admin') {
                    history.push('/admin');
                } else {
                    history.push('/user');
                }
            } else {
                console.error('User data not found or role information missing!');
                setUser(null);
            }
        } catch (error) {
            console.error('Error logging in:', error);
        }
    };

    const logout = async () => {
        try {
            await signOut(auth);
            history.push('/login'); // Redirect to login after logout
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    return (
        <AuthContext.Provider value={{ user, dbInstance, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => useContext(AuthContext);
