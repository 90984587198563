import React from 'react';
import Header from './components-global/Header';

const PrivacyPolicy = () => (
    <div>
        <Header alwaysVisible={true} />
        <div className="container p-5 page-content">
            <div className="privacy-policy-container">
                <h1>Privacy Policy</h1>
                
                <h2>Introduction</h2>
                <p>Welcome to AI Bunny. Your privacy is critically important to us. This Privacy Policy explains how we collect, use, and protect your personal information when you use our website and services.</p>

                <h2>Article 1 - Definitions</h2>
                <p>
                    <strong>Website</strong>  www.aibunny.tech.<br />
                    <strong>Party responsible for processing personal data</strong> : AI Bunny, established at 6545 Market Ave. North STE 100
                    North Canton, OH 44721.<br />
                </p>

                <h2>Article 2 - Access to the Website</h2>
                <p>
                    Access to and use of the website are strictly personal. You will refrain from using the data and information of this website for your own commercial, political, or advertising purposes, as well as for any commercial offers, particularly unsolicited electronic offers.
                </p>

                <h2>Article 3 - Website Content</h2>
                <p>
                    All brands, images, texts, comments, illustrations (animated images), video images, sounds, and all the technical applications used to operate this website and more generally, all components used on this website, are protected by intellectual property laws. Any reproduction, repetition, use, or modification, by any means whatsoever, of all or part of it, including technical applications, without prior written permission from the controller, is strictly prohibited. The fact that the controller may not take immediate action against any infringement cannot be considered as tacit consent, nor a waiver of any right to prosecute the infringing party.
                </p>

                <h2>Article 4 - Management of the Website</h2>
                <p>
                    The controller reserves the right to:
                </p>
                <ul>
                    <li>Suspend, interrupt, reduce, or decline access to the website for a particular category of visitors.</li>
                    <li>Delete all information that may disrupt the functioning of the website or conflicts with national or international laws or is contrary to internet etiquette.</li>
                    <li>Make the website temporarily unavailable to perform updates.</li>
                </ul>

                <h2>Article 5 - Information We Collect</h2>
                <p>
                    • Personal Identification Information: Name, email address, phone number, payment details.<br />
                    • Technical Data: IP address, browser type, operating system, and usage data.<br />
                    • Cookies and Tracking Technologies: To enhance user experience and analyze site traffic.<br />
                    • Customer Interaction Data: Phone number, address, name, and other similar data collected through our AI Agents on platforms such as chatbots, Google Chat, Meta Business products (Instagram API, Facebook Messenger API, WhatsApp Business API), and similar platforms.
                </p>

                <h2>Article 6 - How We Use Your Information</h2>
                <p>We use your personal information to provide our services effectively. This includes processing transactions, communicating with you, and improving our offerings. Customer interaction data collected by our AI Agents is used to better serve our clients and their customers, such as restaurants, pizza stores, law agencies, car dealers, and other businesses that need customer relationship management.</p>

                <h2>Article 7 - Data Sharing</h2>
                <p>We use Google Cloud Firestore Database services to store and manage your data securely. We do not sell, trade, or rent your personal information to others.</p>

                <h2>Article 8 - User Rights</h2>
                <p>
                    Each data subject has the right to information on and access to, and rectification, erasure, and restriction of processing of their personal data, as well as the right to object to the processing and the right to data portability.<br />
                    You can exercise these rights by contacting us at <a href="mailto:aiagent@aibunny.tech">aiagent@aibunny.tech</a>. Each request must be accompanied by a copy of a valid ID, on which you put your signature and state the address where we can contact you. Within one month of the submitted request, you will receive a response from us. Depending on the complexity and number of requests, this period may be extended to two months.
                </p>

                <h2>Article 9 - Cookies and Tracking</h2>
                <p>
                    • Anonymized Analytic Cookies: To obtain information regarding visits to our website, such as the number of visitors, popular pages, and topics. This helps us adjust our communication and information to the needs of our visitors. We cannot see who visits our site or from which personal device the visit has taken place.<br />
                    • Tracking Cookies: Advertising cookies that are intended to show relevant advertisements. By using these cookies, we may deduce your personal interests. Other organizations may show you targeted advertisements when you visit their websites. Tracking cookies make profiling possible and treat categories of people differently when targeting advertisements. Tracking cookies usually process personal data.
                </p>

                <h2>Article 10 - Managing Cookies</h2>
                <p>You can manage your cookie preferences through your browser settings. Please refer to your browser's help section for more information on how to do this.</p>

                <h2>Article 11 - Third-Party Services</h2>
                <p>We use Google Cloud Firestore Database services to store and manage data. No other third-party services are used that may collect user data.</p>

                <h2>Article 12 - Data Retention</h2>
                <p>The collected data is used and retained for the duration determined by law.</p>

                <h2>Article 13 - Policy Changes</h2>
                <p>We will notify you about changes to this privacy policy by sending an email or a notification when you try to log in again.</p>

                <h2>Article 14 - Contact Information</h2>
                <p>For questions, product information, or information about the website itself, please contact:<br />
                    • Email: <a href="mailto:aiagent@aibunny.tech">aiagent@aibunny.tech</a>
                </p>
            </div>
        </div>
    </div>
);

export default PrivacyPolicy;
