import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import UserForm from './UserForm';
import UserList from './UserList';
import '../../css/admin.css';
import { FaSignOutAlt, FaBars, FaUserPlus, FaUsers } from 'react-icons/fa';

const Admin = () => {
    const { user, logout } = useAuth();
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [activeTab, setActiveTab] = useState('AddUser');

    if (!user) {
        return <div className="access-denied">Access denied: No user data.</div>;
    }

    if (user.role !== 'admin') {
        return <div className="access-denied">Access denied: You are not an admin.</div>;
    }

    const handleSidebarToggle = () => setSidebarOpen(!sidebarOpen);

    const renderContent = () => {
        switch (activeTab) {
            case 'AddUser':
                return <UserForm setActiveTab={setActiveTab} />;
            case 'Users':
                return <UserList />;
            default:
                return null;
        }
    };

    return (
        <div className="custom-user-container">
            <div className={`custom-sidebar custom-main-sidebar ${sidebarOpen ? '' : 'collapsed'}`}>
                <div className="header-section">
                    <button className="toggle-sidebar" onClick={handleSidebarToggle}>
                        <FaBars />
                        {sidebarOpen && <span className="sidebar-label">{activeTab}</span>}
                    </button>
                </div>
                {sidebarOpen && (
                    <div className="scrollable-tabs">
                        <button className={activeTab === 'AddUser' ? 'active' : ''} onClick={() => setActiveTab('AddUser')}><FaUserPlus /> Add User</button>
                        <button className={activeTab === 'Users' ? 'active' : ''} onClick={() => setActiveTab('Users')}><FaUsers /> Users</button>
                    </div>
                )}
                <div className="footer-section">
                    <div className="custom-user-info">
                        <span>{user?.email}</span>
                        <button className="custom-logout-button" onClick={logout}><FaSignOutAlt /> Logout</button>
                    </div>
                </div>
            </div>
            <div className="custom-content custom-main-content">
                {renderContent()}
            </div>
        </div>
    );
};

export default Admin;
