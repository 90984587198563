import { auth, db } from './firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';

export const handleScheduleClick = () => {
    window.open('https://aibunny.zohobookings.com/#/aibunny', '_blank');
};

export const registerUser = async (email, password, company, companyType, role = 'user') => {
    try {
        const currentUser = auth.currentUser;
        const currentUserEmail = currentUser.email;
        const currentUserPassword = prompt('Please re-enter your password to create a new user:', '');

        if (!currentUserPassword) {
            throw new Error('Password is required to create a new user.');
        }

        await createUserWithEmailAndPassword(auth, email, password);
        const user = auth.currentUser;
        await setDoc(doc(db, 'users', user.uid), {
            email: user.email,
            company: company,
            companyType: companyType,
            role: role
        });
        console.log('User created and added to Firestore');

        await signOut(auth);
        await signInWithEmailAndPassword(auth, currentUserEmail, currentUserPassword);
        console.log('Switched back to the original user');
    } catch (error) {
        console.error('Error creating user:', error);
    }
};


export const getUserData = async (uid) => {
    try {
        const userDoc = await getDoc(doc(db, 'users', uid));
        if (userDoc.exists()) {
            return userDoc.data();
        } else {
            console.log('No such document!');
            return null;
        }
    } catch (error) {
        console.error('Error getting user data:', error);
        return null;
    }
};

export const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';

    const [hours, minutes] = timestamp.split(':');
    let hours12 = hours % 12 || 12;
    let period = hours >= 12 ? 'PM' : 'AM';

    return `${hours12}:${minutes} ${period}`;
};

export const linkify = (text) => {
    const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    return text.replace(urlPattern, '<a href="$1" target="_blank">$1</a>');
};
