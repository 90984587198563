import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { formatTimestamp } from '../../../../utils';

const TicketsSidebar = ({ date, setDate, tickets, selectedTicket, handleSelectItem, setActiveTab }) => (
    <>
        <div className="sidebar-header">
            <button onClick={() => setActiveTab('main')} className="back-button"><FaArrowLeft /></button>
            <input type="date" value={date} onChange={e => setDate(e.target.value)} className="custom-date-picker" />
        </div>
        <div className="scrollable-content">
            {tickets.length === 0 ? <div>No data</div> : tickets.map((ticket, index) => {
                const label = ticket.data.customer_name;
                const typeLabel = ticket.type === 'pickup' ? 'Pickup' : 'Delivery';
                const formattedTime = ticket.data.date_time
                    ? new Date(ticket.data.date_time).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
                    : formatTimestamp(new Date(ticket.data.created_at.seconds * 1000).toISOString());

                return (
                    <button key={index} className={selectedTicket === ticket.id ? 'active' : ''} onClick={() => handleSelectItem(ticket.id)}>
                        <div >
                            <div>{label}</div>
                            <div>
                                <small>{typeLabel}</small>
                                <small> {formattedTime}</small>
                            </div>
                        </div>
                    </button>
                );
            })}
        </div>
    </>
);

export default TicketsSidebar;
