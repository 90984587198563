import React, { useState } from 'react';
import Blog from './components-home/Blog';
import Contact from './components-home/Contact';
import Feature from './components-home/Feature';
import FeatureOne from './components-home/FeatureOne';
import FeatureTwo from './components-home/FeatureTwo';
import Heroarea from './components-home/Heroarea';
import Pricing from './components-home/Pricing';
import Testimonials from './components-home/Testimonials';
import Services from './components-home/Services';
import Preface from './components-home/Preface';
import Howitworks from './components-home/Howitworks';
import Screenshots from './components-home/Screenshots';
import ChatBot from './components-home/ChatBot';
import Notification from './components-home/Notification';

const Home = () => {
    const [notification, setNotification] = useState({ message: '', type: '' });

    const showNotification = (message, type) => {
        setNotification({ message, type });
        setTimeout(() => {
            setNotification({ message: '', type: '' });
        }, 3000);
    };

    return (
        <div>
            <Heroarea />
            <Feature />
            <Testimonials />
            <Services />
            <Pricing />
            <Contact showNotification={showNotification} />
            <ChatBot />
            <Notification notification={notification} onClose={() => setNotification({ message: '', type: '' })} />
        </div>
    );
}

export default Home;
