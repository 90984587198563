import React from 'react';
import Header from './components-global/Header';

const TermsOfService = () => (
    <div>
        <Header alwaysVisible={true} />
        <div className="container p-5 page-content">
            <div className="terms-of-service-container">
                <h1>Terms of Service</h1>
                
                <h2>Introduction</h2>
                <p>Welcome to AI Bunny. By accessing or using our website and services, you agree to be bound by these Terms of Service. If you do not agree to these terms, please do not use our services.</p>

                <h2>Article 1 - Definitions</h2>
                <p>
                    <strong>Website</strong>: www.aibunny.tech<br />
                    <strong>Services</strong>: The AI-driven solutions and services provided by AI Bunny.<br />
                    <strong>User</strong>: Anyone who accesses or uses the Website and Services.<br />
                    <strong>Content</strong>: All information, text, graphics, or other materials uploaded, downloaded, or appearing on the Website.
                </p>

                <h2>Article 2 - Use of Services</h2>
                <p>
                    Users must comply with all applicable laws and regulations while using the Services.<br />
                    Users must not use the Services for any unlawful or unauthorized purpose, including but not limited to distributing any harmful or illegal content.
                </p>

                <h2>Article 3 - User Accounts</h2>
                <p>
                    Users may need to create an account to access certain features of the Services.<br />
                    Users are responsible for maintaining the confidentiality of their account information and for all activities that occur under their account.
                </p>

                <h2>Article 4 - Content Ownership and Rights</h2>
                <p>
                    All content on the Website is the property of AI Bunny or its licensors.<br />
                    Users retain ownership of any content they post but grant AI Bunny a worldwide, non-exclusive, royalty-free license to use, reproduce, and display such content.
                </p>

                <h2>Article 5 - Privacy</h2>
                <p>Your use of the Services is also governed by our Privacy Policy, which describes how we collect, use, and protect your personal information.</p>

                <h2>Article 6 - Intellectual Property</h2>
                <p>Users must not reproduce, distribute, or create derivative works from any content on the Website without prior written consent from AI Bunny.</p>

                <h2>Article 7 - Termination</h2>
                <p>AI Bunny reserves the right to terminate or suspend access to the Services for any reason, including violation of these Terms, without prior notice.</p>

                <h2>Article 8 - Limitation of Liability</h2>
                <p>AI Bunny is not liable for any indirect, incidental, special, consequential, or punitive damages arising from the use of the Services.</p>

                <h2>Article 9 - Changes to Terms</h2>
                <p>AI Bunny reserves the right to modify these Terms at any time. Users will be notified of significant changes through the Website or email.</p>

                <h2>Article 10 - Governing Law</h2>
                <p>These Terms are governed by the laws of the State of Ohio, without regard to its conflict of law principles.</p>

                <h2>Contact Information</h2>
                <p>For questions about these Terms of Service, please contact:<br />
                    • Email: <a href="mailto:aiagent@aibunny.tech">aiagent@aibunny.tech</a>
                </p>
            </div>
        </div>
    </div>
);
export default TermsOfService;
