import React from 'react';
import { useAuth } from '../../context/AuthContext';
import RestaurantUser from './Restaurant/RestaurantUser';
import DefaultView from './DefaultView';

function User() {
    const { user } = useAuth();

    const renderUserComponent = () => {
        switch (user?.companyType) {
            case 'restaurant':
                return <RestaurantUser />;
            default:
                return <DefaultView />;
        }
    };

    return (
        <div>
            {renderUserComponent()}
        </div>
    );
}

export default User;
