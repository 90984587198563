import React, { useState, useEffect } from 'react';
import { getDoc, doc, getFirestore } from 'firebase/firestore';
import { initializeSohoPizzaApp } from '../../../../firebase';
import { useAuth } from '../../../../context/AuthContext';
import '../../../../css/sohopizza/MonthlyReport.css';

const sohoPizzaApp = initializeSohoPizzaApp();
const sohoPizzaDb = getFirestore(sohoPizzaApp);

const MonthlyReport = ({ selectedMonth }) => {
    const { user } = useAuth();
    const [reportData, setReportData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const getFormattedCompany = () => {
        return user?.company.split(' ')[0].toUpperCase();
    };

    useEffect(() => {
        const fetchMonthlyReport = async () => {
            if (selectedMonth) {
                setLoading(true);
                setError('');

                const formattedCompany = getFormattedCompany();
                const monthPath = `${formattedCompany}/other/MONTHLY_REPORTS/${selectedMonth}`;
                const docRef = doc(sohoPizzaDb, monthPath);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    setReportData(docSnap.data());
                } else {
                    setReportData(null);
                    setError('No report available for the selected month.');
                }

                setLoading(false);
            }
        };

        fetchMonthlyReport();
    }, [selectedMonth]);

    if (!selectedMonth) {
        return <div className="report-message">Select a month to view the report</div>;
    }

    if (loading) {
        return <div className="report-message">Loading...</div>;
    }

    if (error) {
        return <div className="report-message">{error}</div>;
    }

    return (
        <div className="report-content">
            <h2>{selectedMonth} Report</h2>
            <div className="report-list">
                <div className="report-item">
                    <span className="report-label">Delivery Monthly Income:</span>
                    <span className="report-value">{reportData.delivery_monthly_income}</span>
                </div>
                <div className="report-item">
                    <span className="report-label">Pickup Monthly Income:</span>
                    <span className="report-value">{reportData.pickup_monthly_income}</span>
                </div>
                <div className="report-item">
                    <span className="report-label">Total Delivery Orders:</span>
                    <span className="report-value">{reportData.total_delivery_orders}</span>
                </div>
                <div className="report-item">
                    <span className="report-label">Total Monthly Income:</span>
                    <span className="report-value">{reportData.total_monthly_income}</span>
                </div>
                <div className="report-item">
                    <span className="report-label">Total Orders:</span>
                    <span className="report-value">{reportData.total_orders}</span>
                </div>
                <div className="report-item">
                    <span className="report-label">Total Pickup Orders:</span>
                    <span className="report-value">{reportData.total_pickup_orders}</span>
                </div>
            </div>
        </div>
    );
};

export default MonthlyReport;
