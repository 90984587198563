import React from 'react';

const DriverTicketItem = ({ ticket, selectedTickets, handleTicketSelection, isCurrentDate }) => {
    return (
        <div
            className={`ticket-box ${selectedTickets.includes(ticket.id) ? 'selected' : ''}`}
            onClick={() => handleTicketSelection(ticket.id)}
        >
            <input
                type="checkbox"
                checked={selectedTickets.includes(ticket.id)}
                className="ticket-checkbox"
                readOnly
            />
            <p className="ticket-number">
                Delivery {ticket.ticket_number}
                <span className="ticket-total">${ticket['FINAL TOTAL']}</span>
            </p>
            <div className="ticket-details">
                <p><strong>{ticket.customer_name}</strong> {ticket.address}</p>
            </div>
        </div>
    );
};

export default DriverTicketItem;
