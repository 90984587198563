import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { FaTrash, FaEdit } from 'react-icons/fa';

const UserList = () => {
    const [users, setUsers] = useState([]);
    const [editUser, setEditUser] = useState(null);
    const [email, setEmail] = useState('');
    const [companyType, setCompanyType] = useState('');
    const [company, setCompany] = useState('');
    const [role, setRole] = useState('');

    const companyOptions = {
        restaurant: ['Soho Pizza', 'Noho Pizza'],
        technology: ['Ai Bunny']
    };

    useEffect(() => {
        const fetchUsers = async () => {
            const querySnapshot = await getDocs(collection(db, 'users'));
            const userList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setUsers(userList);
        };

        fetchUsers();
    }, []);

    const handleDelete = async (id) => {
        await deleteDoc(doc(db, 'users', id));
        setUsers(users.filter(user => user.id !== id));
    };

    const handleEdit = (user) => {
        setEditUser(user);
        setEmail(user.email);
        setCompanyType(user.companyType);
        setCompany(user.company);
        setRole(user.role);
    };

    const handleCompanyTypeChange = (e) => {
        setCompanyType(e.target.value);
        setCompany(companyOptions[e.target.value][0]);
    };

    const handleUpdate = async () => {
        const userRef = doc(db, 'users', editUser.id);
        await updateDoc(userRef, { email, company, companyType, role });
        setUsers(users.map(user => user.id === editUser.id ? { ...user, email, company, companyType, role } : user));
        setEditUser(null);
        setEmail('');
        setCompany('');
        setCompanyType('');
        setRole('');
    };

    return (
        <div className="user-list-container">
            <h2>User List</h2>
            {editUser ? (
                <div className="edit-user-form">
                    <h3>Edit User</h3>
                    <div className="form-group">
                        <label>Email</label>
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label>Role</label>
                        <select value={role} onChange={(e) => setRole(e.target.value)}>
                            <option value="user">User</option>
                            <option value="admin">Admin</option>
                        </select>
                    </div>
                    {role === 'user' && (
                        <>
                            <div className="form-group">
                                <label>Company Type</label>
                                <select value={companyType} onChange={handleCompanyTypeChange}>
                                    <option value="restaurant">Restaurant</option>
                                    <option value="technology">Technology</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Company</label>
                                <select value={company} onChange={(e) => setCompany(e.target.value)}>
                                    {companyOptions[companyType].map((comp) => (
                                        <option key={comp} value={comp}>{comp}</option>
                                    ))}
                                </select>
                            </div>
                        </>
                    )}
                    <button onClick={handleUpdate}>Update User</button>
                    <button onClick={() => setEditUser(null)}>Cancel</button>
                </div>
            ) : (
                <table>
                    <thead>
                    <tr>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Company</th>
                        <th>Company Type</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {users.map(user => (
                        <tr key={user.id}>
                            <td>{user.email}</td>
                            <td>{user.role}</td>
                            <td>{user.company}</td>
                            <td>{user.companyType}</td>
                            <td>
                                <button onClick={() => handleEdit(user)}><FaEdit /></button>
                                <button onClick={() => handleDelete(user.id)}><FaTrash /></button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default UserList;
