import React, { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { sohoPizzaDb } from '../RestaurantUser';
import { useAuth } from '../../../../context/AuthContext';

const DailyReportContent = ({ date, reportType, handleGoToTicket }) => {
    const { user } = useAuth();
    const [reportData, setReportData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [noData, setNoData] = useState(false);

    const getFormattedCompany = () => {
        return user?.company.split(' ')[0].toUpperCase();
    };

    useEffect(() => {
        const fetchReportData = async () => {
            setLoading(true);
            setNoData(false);
            setReportData(null);
            try {
                const formattedCompany = getFormattedCompany();
                const path = `${formattedCompany}/date/${date}/${reportType}`;
                const docRef = doc(sohoPizzaDb, path);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    setReportData(docSnap.data());
                } else {
                    setNoData(true);
                }
            } catch (error) {
                console.error('Error fetching report data:', error);
                setNoData(true);
            } finally {
                setLoading(false);
            }
        };

        fetchReportData();
    }, [date, reportType]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (noData || !reportData) {
        return <div>No data available for the selected report.</div>;
    }

    const formatTime = (time) => {
        const [hour, minute, second] = time.split(':').map(Number);
        const period = hour >= 12 ? 'PM' : 'AM';
        const formattedHour = hour % 12 || 12;
        return `${formattedHour}:${minute.toString().padStart(2, '0')} ${period}`;
    };

    const formatOrder = (order) => {
        const parts = order.split(',');

        const ticketNumber = parts[0].split(':')[1]?.trim();
        const time = formatTime(parts[1].split('time:')[1]?.trim());

        const paymentStatus = parts.pop()?.trim();
        const total = parts.pop().split(':')[1]?.trim();
        const phone = parts.pop().split(':')[1]?.trim();
        const name = parts.pop()?.trim();

        const address = reportType === 'Delivery Report' ? parts.slice(2).join(',').trim() : null;

        const handleGoToTicketClick = () => {
            handleGoToTicket(name, date);
        };

        return (
            <div className="order-item">
                <span className="order-name">{name}</span>
                <span className={`payment-status ${paymentStatus === 'PAID' ? 'paid' : 'not-paid'}`}>{paymentStatus}</span>
                <span className="order-total">${total}</span>
                <span className="order-time">{time}</span>
                <button className="go-to-ticket-button" onClick={handleGoToTicketClick}>Go to Ticket</button>
            </div>
        );
    };

    return (
        <div className="daily-report-container">
            <div className="report-header">
                <h2>{reportType}</h2>
                <div className="report-summary">
                    <p><strong>Total Income:</strong> ${reportData.total_income.toFixed(2)}</p>
                    <p><strong>Total Orders:</strong> {reportData.total_orders}</p>
                </div>
            </div>

            {reportData.order_list.map((order, index) => (
                <li key={index} className="order-item-container">
                    {formatOrder(order)}
                </li>
            ))}

        </div>
    );
};

export default DailyReportContent;
