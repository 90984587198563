import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Varsayılan Firebase yapılandırması
const firebaseConfig = {
    apiKey: "AIzaSyCXp4JyKIcoaz8q30L0FCzsbY7HNshAQP0",
    authDomain: "website-428819.firebaseapp.com",
    projectId: "website-428819",
    storageBucket: "website-428819.appspot.com",
    messagingSenderId: "345545941053",
    appId: "1:345545941053:web:f5c85cde3982ce2ef40a54"
};

// Soho Pizza Firebase yapılandırması
const sohoPizzaConfig = {
    apiKey: "AIzaSyCLRJ-gUMJsBnpW_BeGHf1_yuCE2gRILOY",
    authDomain: "soho-pizza-428813.firebaseapp.com",
    projectId: "soho-pizza-428813",
    storageBucket: "soho-pizza-428813.appspot.com",
    messagingSenderId: "473829180643",
    appId: "1:473829180643:web:a4d5abd0feb545613e71d6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Auth and Firestore
export const auth = getAuth(app);
export const db = getFirestore(app);

// Soho Pizza için Firebase'i başlatan fonksiyon
export const initializeSohoPizzaApp = () => {
    return initializeApp(sohoPizzaConfig, "sohoPizza");
};
