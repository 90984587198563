import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';

const DailyReportSidebar = ({ date, setDate, setActiveTab, reportType, setReportType }) => (
    <>
        <div className="sidebar-header">
            <button onClick={() => setActiveTab('main')} className="back-button"><FaArrowLeft /></button>
            <input type="date" value={date} onChange={e => setDate(e.target.value)} className="custom-date-picker" />
        </div>
        <div className="scrollable-content">
            <button onClick={() => setReportType('Pickup Report')} className={reportType === 'Pickup Report' ? 'active' : ''}>Pickup Report</button>
            <button onClick={() => setReportType('Delivery Report')} className={reportType === 'Delivery Report' ? 'active' : ''}>Delivery Report</button>
        </div>
    </>
);

export default DailyReportSidebar;
