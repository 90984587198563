import React from 'react';
import { FaTimes, FaMinus } from 'react-icons/fa';
import { sohoPizzaDb } from "../RestaurantUser";
import { useAuth } from '../../../../context/AuthContext';
import { doc, setDoc, updateDoc, deleteField, collection, getDocs } from 'firebase/firestore';

const DriverModal = ({ selectedDriver, onClose, notPaidDeliveryTickets, setNotPaidDeliveryTickets, driversList, setDriversList, selectedDate, setSelectedDriver }) => {
    const { user } = useAuth();

    const getFormattedCompany = () => {
        return user?.company.split(' ')[0].toUpperCase();
    };

    const getFormattedCompanyForPath = () => {
        return user?.company.replace(/\s+/g, '');
    };

    const fetchNotPaidDeliveryTickets = async () => {
        try {
            const formattedCompany = getFormattedCompany();
            const path = `${formattedCompany}/date/${selectedDate}/delivery/tickets`;
            const collectionRef = collection(sohoPizzaDb, path);
            const querySnapshot = await getDocs(collectionRef);
            const ticketsData = querySnapshot.docs
                .map(doc => ({ id: doc.id, ...doc.data() }))
                .filter(ticket => ticket['Payment Status'] === 'NOT-PAID' && !ticket.isAssigned);

            setNotPaidDeliveryTickets(ticketsData);
        } catch (error) {
            console.error('Error fetching tickets:', error);
        }
    };

    const handleRemoveTicket = async (driverId, ticketId) => {
        const driver = driversList.find(driver => driver.id === driverId);
        if (driver) {
            const ticketToMoveBack = driver.tickets.find(ticket => ticket.id === ticketId);
            const updatedDriver = {
                ...driver,
                tickets: driver.tickets.filter(ticket => ticket.id !== ticketId)
            };

            const driverDocRef = doc(sohoPizzaDb, `companies/${getFormattedCompanyForPath()}/drivers`, driverId);
            await updateDoc(driverDocRef, { tickets: updatedDriver.tickets });

            const actualTicketId = `Ticket_${ticketId.split('_')[1]}`;
            const formattedCompany = getFormattedCompany();
            const ticketDocRef = doc(sohoPizzaDb, `${formattedCompany}/date/${selectedDate}/delivery/tickets`, actualTicketId);
            await updateDoc(ticketDocRef, { isAssigned: deleteField() });

            setDriversList(driversList.map(d => d.id === driverId ? updatedDriver : d));
            await fetchNotPaidDeliveryTickets();
            setSelectedDriver({
                ...selectedDriver,
                tickets: selectedDriver.tickets.filter(ticket => ticket.id !== ticketId)
            });
        }
    };

    const handleCloseDay = async (driver) => {
        if (!driver.tickets || driver.tickets.length === 0) {
            alert('No tickets to close.');
            return;
        }

        const currentDate = new Date().toISOString().substr(0, 10);
        const reportPath = `companies/${getFormattedCompanyForPath()}/${driver.name.replace(/\s+/g, '_')}_reports/${currentDate}`;
        const docRef = doc(sohoPizzaDb, reportPath);

        try {
            await setDoc(docRef, { tickets: driver.tickets });
            const driverDocRef = doc(sohoPizzaDb, `companies/${getFormattedCompanyForPath()}/drivers`, driver.id);
            await updateDoc(driverDocRef, { tickets: [], isActive: false, isClosed: true });
            setDriversList(driversList.map(d => d.id === driver.id ? { ...driver, tickets: [], isActive: false, isClosed: true } : d));
            setSelectedDriver(null);
            alert('Day closed successfully.');
        } catch (error) {
            alert('Failed to close day.');
        }
    };

    return (
        <div className="driver-modal">
            <div className="modal-content">
                <button className="close-button" onClick={onClose}><FaTimes /></button>
                <h2>{selectedDriver.name}'s {selectedDriver.report ? 'Report' : 'Tickets'}</h2>
                <ul>
                    {(selectedDriver.report || selectedDriver.tickets || []).map(ticket => (
                        <li key={ticket.id} className="ticket-box">
                            {selectedDriver.report ? (
                                <>
                                    <p className="ticket-number">
                                        Delivery {ticket.ticket_number}
                                        <span className="ticket-total">${ticket.final_total}</span>
                                    </p>
                                    <div className="ticket-details">
                                        <span><strong>{ticket.customer_name}</strong> {ticket.address}</span>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <button className="remove-ticket-button" onClick={() => handleRemoveTicket(selectedDriver.id, ticket.id)}>
                                        <FaMinus />
                                    </button>
                                    <p className="ticket-number">
                                        Delivery {ticket.ticket_number}
                                        <span className="ticket-total">${ticket.final_total}</span>
                                    </p>
                                    <div className="ticket-details">
                                        <span><strong>{ticket.customer_name}</strong> {ticket.address}</span>
                                    </div>
                                </>
                            )}
                        </li>
                    ))}
                </ul>
                <p>
                    <strong>Total:</strong> ${selectedDriver.report ? selectedDriver.report.reduce((acc, ticket) => acc + parseFloat(ticket.final_total), 0).toFixed(2) : selectedDriver.tickets.reduce((acc, ticket) => acc + parseFloat(ticket.final_total), 0).toFixed(2)}
                </p>
                {!selectedDriver.report && (
                    <div className="modal-buttons">
                        <button className="print-button">Print</button>
                        <button className="close-day-button" onClick={() => handleCloseDay(selectedDriver)}>Close Day</button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DriverModal;
