import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import {Route, Switch, Redirect, useHistory, BrowserRouter} from 'react-router-dom';
import blogdata from "./data/blogdata.json";
import Singleblogdata from "./data/single-blogdata.json";
import Footerdata from "./data/footerdata.json";

import Home from "./components/Home";
import Header from "./components/components-global/Header";
import Footer from "./components/components-global/Footer";
import Blogfull from "./components/components-blog/Blogfull";
import Blog2col from "./components/components-blog/blog-2-col";
import Blog3col from "./components/components-blog/blog-3-col";
import Blogfullleftsidebar from "./components/components-blog/blog-full-left-sidebar";
import Blogfullrightsidebar from "./components/components-blog/blog-full-right-sidebar";
import Singleblog from "./components/components-blog/single-blog";
import Singleleftsidebar from "./components/components-blog/single-left-sidebar";
import Singlerightsidebar from "./components/components-blog/single-right-sidebar";
import ScrollToTop from "./components/components-global/ScrollToTop";

import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";

import Admin from "./components/user/Admin";
import Login from "./components/user/Login";
import User from "./components/user/User"; // Yeni user bileşenini ekleyin
import { AuthProvider, useAuth } from "./context/AuthContext";

function PrivateRoute({ children, ...rest }) {
    let { user } = useAuth();
    return (
        <Route
            {...rest}
            render={({ location }) =>
                user ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}

function Root() {
    const { user } = useAuth();
    const history = useHistory();

    useEffect(() => {
        if (user) {
            if (history.location.pathname === "/login") {
                if (user.role === 'admin') {
                    history.push('/admin');
                } else {
                    history.push('/user');
                }
            }
        }
    }, [user, history]);

    return (
        <div>
            <ScrollToTop />
            <Switch>
                <Route path="/" exact>
                    <Header />
                    <Home />
                    <Footer data={Footerdata} />
                </Route>
                <Route path="/blog-full">
                    <Header />
                    <Blogfull data={blogdata} />
                    <Footer data={Footerdata} />
                </Route>
                <Route path="/blog-2-col">
                    <Header />
                    <Blog2col data={blogdata} />
                    <Footer data={Footerdata} />
                </Route>
                <Route path="/blog-3-col">
                    <Header />
                    <Blog3col data={blogdata} />
                    <Footer data={Footerdata} />
                </Route>
                <Route path="/blog-full-left-sidebar">
                    <Header />
                    <Blogfullleftsidebar data={blogdata} />
                    <Footer data={Footerdata} />
                </Route>
                <Route path="/blog-full-right-sidebar">
                    <Header />
                    <Blogfullrightsidebar data={blogdata} />
                    <Footer data={Footerdata} />
                </Route>
                <Route path="/single-blog">
                    <Header />
                    <Singleblog data={Singleblogdata} />
                    <Footer data={Footerdata} />
                </Route>
                <Route path="/single-left-sidebar">
                    <Header />
                    <Singleleftsidebar data={Singleblogdata} />
                    <Footer data={Footerdata} />
                </Route>
                <Route path="/single-right-sidebar">
                    <Header />
                    <Singlerightsidebar data={Singleblogdata} />
                    <Footer data={Footerdata} />
                </Route>
                <Route path="/privacy-policy">
                    <Header />
                    <PrivacyPolicy />
                    <Footer data={Footerdata} />
                </Route>
                <Route path="/terms-of-service">
                    <Header />
                    <TermsOfService />
                    <Footer data={Footerdata} />
                </Route>
                <Route path="/login">
                    {user ? (
                        user.role === 'admin' ? <Redirect to="/admin" /> : <Redirect to="/user" />
                    ) : (
                        <Login />
                    )}
                </Route>
                <PrivateRoute path="/admin">
                    <Admin />
                </PrivateRoute>
                <PrivateRoute path="/user">
                    <User />
                </PrivateRoute>
                <Redirect from="*" to="/" />
            </Switch>
        </div>
    );
}

function RootWithProvider() {
    return (
        <AuthProvider>
            <BrowserRouter>
                <Root />
            </BrowserRouter>
        </AuthProvider>
    );
}

ReactDOM.render(<React.StrictMode><RootWithProvider /></React.StrictMode>, document.getElementById("root"));
